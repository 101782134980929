import React, { useEffect, useState } from 'react';
import { navigate } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';
import { useBuilder } from '../GraphQl/useBuilder';

// Components
import Layout from '../components/layout';
import Seo from '../components/global/seo';
import { PortableTextBlock } from "../components/ui/portableTextBlock"

const Checkout = ({}) => {  

  const { 
    checkoutTitle,
    checkoutText
   } = useBuilder()
  
  const { 
    setFooterHidden, 
    setHeaderDark, 
    cartProject, 
    setCartProject, 
    setFooterTheme,
    setCartEditItem
  } = useSiteState();

  useEffect(() => {    
    document.documentElement.setAttribute('data-theme', `theme-black`);
    document.documentElement.style.setProperty(`--doc-bg`, '#141414');                  
    setHeaderDark(false)    
    setFooterHidden(true)
    setFooterTheme('theme-none')
  }, [setFooterHidden, setFooterTheme, setHeaderDark]);

  const [cartItems, setCartItems] = useState([]);
  const [userName, setUserName] = useState();  
  const [userEmail, setUserEmail] = useState(undefined);  
  const [userPhone, setUserPhone] = useState(undefined);  
  const [userCompany, setUserCompany] = useState('');  
  const [checkoutStatus, setCheckoutStatus] = useState(undefined);  
    
  const checkoutItems = undefined; 

  function buildTable(data){
        
		var contentHtml = ''

    data.map((item) => {

      contentHtml += `<p><strong>${item.product ? item.product : 'Item'}</strong></p><ul>`

      // item.product && (contentHtml += `<li>Product: ${item.product}</li>`)
      item.size && (contentHtml += `<li>Size: ${item.size}${item.size === 'Custom' ? (
        ` - ${item.customWidth `${item.customWidth}W`} ${item.customLength && ` ${item.customLength}L`}${item.customHeight && ` ${item.customHeight}H`}${item.customUnit && ` ${item.customUnit}`}`
      ) : (``)}</li>`)      
      item.orientation && (contentHtml += `<li>Orientation: ${item.orientation}</li>`)
      item.material && (contentHtml += `<li>Material: ${item.material}${item.specialtySpec ? ` - ${item.specialtySpec}` : ``}</li>`)
      item.materialType && (contentHtml += `<li>Material: ${item.materialType}</li>`)
      item.laminate && (contentHtml += `<li>Laminate: ${item.laminate}</li>`)
      item.printing && (contentHtml += `<li>Printing: ${item.printing}</li>`)
      item.closure && (contentHtml += `<li>Closure: ${item.closure}</li>`)
      item.embossing && (contentHtml += `<li>Emboss/Deboss: ${item.embossing}</li>`)
      item.texture && (contentHtml += `<li>Textures: ${item.texture}</li>`)
      item.foil && (contentHtml += `<li>Foils: ${item.foil}</li>`)
      item.finish && (contentHtml += `<li>Finish: ${item.finish}</li>`)
      item.finish && (contentHtml += `<li>Finish: ${item.finish}</li>`)
      item.quantity && (contentHtml += `<li>Quantity: ${item.quantity}${item.quantityCustom ? item.quantityCustom : ''}</li>`)      
      item.leadTime && (contentHtml += `<li>Lead Time: ${item.leadTime}</li>`)
      item.notes && (contentHtml += `<li>Additional Notes: ${item.notes}</li>`)
        
      contentHtml += '</ul>'
    })		

    return contentHtml
    
	}


    useEffect(() => {      
    const items = JSON.parse(localStorage.getItem('cart'));        
    const checkoutItems = JSON.parse(localStorage.getItem('cart'));         
    
    if (items) {
        setCartItems(items);
    }
    }, [setCartItems]);  
    

    const createNote = async (id) => {         
      
      const theData = buildTable(cartItems)    

      const response = await window
        .fetch(`/api/addNote`, {
          method: `POST`,
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            dealId: id,
            content: theData
        }),          
        })      
        .then((response) => response.json())
        .then((data) => {
          if (data.success === false) {                
              setCheckoutStatus(false)                            
              return
          }
          if (data.success === true) {                                                      
            // setCheckoutStatus(true)
            const isBrowser = typeof window !== "undefined"
            if (isBrowser) {
              window.localStorage.setItem('cart', []);
            }            
            navigate(`/enquiry-submitted`)            
          }
        });
    }
    
        

    const createDeal = async () => {      
      setCheckoutStatus('submitting')
      const response = await window
        .fetch(`/api/addDeal`, {
          method: `POST`,
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            project: cartProject !== null ? cartProject :  userName,
            name: userName,
            company: userCompany,
            email: userEmail,
            phone: userPhone,
            cart: cartItems
        }),          
        })      
        .then((response) => response.json())
        .then((data) => {
          if (data.success === false) {              
              setCheckoutStatus(false)                            
              return
          }
          if (data.success === true) {                                                                  
            createNote(data.data.id)            
          }
        });
    }    

  return (
    <Layout>      
      <Seo metaTitle="Checkout"  hidden={true}/>
      <div className="block w-full h-24 lg:h-header"></div>
      <section id="cart" className="grid-std grid-flow-row lg:items-start px-gutter lg:px-0 lg:pt-20 pb-28">
            <div className="col-span-full lg:sticky lg:top-0 lg:row-start-1 lg:col-start-2 lg:col-end-10 mb-12 lg:mb-0">
                <h1 className="text-lg lg:text-xl">
                    {checkoutTitle !== null && <div>{checkoutTitle}</div>}
                    <div>
                    {cartItems && cartItems.length > 0 ? (
                      cartItems.length > 1 ? `${cartItems.length} items` : `${cartItems.length} item`
                    ) : (
                      `Your cart is empty.`
                    )}
                    </div>
                </h1>

                {checkoutText && <PortableTextBlock className="prose md:max-w-md mt-6" text={checkoutText} />}

                <form class="mt-6 styled-form md:max-w-md grid grid-cols-1 grid-flow-row gap-6">
                    <input type="text" name="name" placeholder="Name*" value={userName} onChange={(e) => setUserName(e.target.value)} required />
                    <input type="text" name="company" placeholder="Company*" value={userCompany} onChange={(e) => setUserCompany(e.target.value)} required />
                    <input type="email" name="email" placeholder="Email address*" value={userEmail} onChange={(e) => setUserEmail(e.target.value)}  required />
                    <input type="number" name="phone" placeholder="Phone number*" value={userPhone} onChange={(e) => setUserPhone(e.target.value)}  required />
                    {cartItems && cartItems.length > 0 && (
                    <div className="flex justify-end mb-gutter">
                        <button onClick={()=>{createDeal()}} type="button" className={`${checkoutStatus === 'submitting' ? 'pointer-events-none' : ''} btn-rnd-white ${userName && userEmail && userPhone ? 'cursor-pointer' : 'opacity-40 pointer-events-none'}`}>{checkoutStatus === 'submitting' ? 'Submit project' : 'Submitting'}</button>
                    </div>            
                    )}   
                    {checkoutStatus === 'submitting' && (
                        <p>Please do not refresh your page.</p>
                    )}
                    {checkoutStatus === true && (
                        <p>Submission successful.</p>
                    )}
                    {checkoutStatus === false && (
                        <p>There was an error.</p>
                    )}
                </form>

            </div>            

            <ul className="col-span-full lg:col-start-12 lg:col-end-26 lg:row-start-1 lg:row-end-3 px-gutter lg:px-0">
            <li>
                <form className="flex items-baseline justify-between md:justify-start mb-2">
                  <div className={`bg-transparent appearance-none py-2 outline-none border-b border-transparent  text-base md:text-lg mb-4 mr-2 `} type="text" placeholder="Untitled project"  name="cartProject">{cartProject}</div>                                    
                  <Link className="underline" to={'/cart'}>Edit project</Link>
                </form>                
              </li>
            {cartItems.length > 0 ? (
                cartItems.map((item, index) => {
                    return (
                        <li className="grid grid-cols-4 grid-flow-row gap-4 pb-4 mb-4 border-b border-white/50">
                          <div className="col-span-4 md:col-span-1 overflow-hidden transition-all duration-300 hover:rounded-card">
                            <Link to={`/packaging/${item.slug.current}`}>
                              <GatsbyImage 
                                  image={item.image.asset.gatsbyImageData} 
                                  alt={item.product} 
                                  className="block w-full h-auto" 
                              />
                            </Link>
                          </div>
                          <div className="col-span-3 md:col-span-2">
                              <p className="mb-8">{item.product}</p>
                              <table className="w-full table-fixed">
                                {item.size && <tr><td className="opacity-50">Size:</td>
                                  <td>{item.size}
                                  {item.size === 'Custom' && (
                                    <>
                                    {' - '}
                                    {item.customWidth && item.customWidth}
                                    {` X `}
                                    {item.customLength && item.customLength}
                                    {` X `}
                                    {item.customHeight && item.customHeight}
                                    {` `}
                                    {item.customUnit && item.customUnit}
                                    </>
                                  )}
                                  </td>
                                  </tr>
                                }
                                {item.orientation && <tr><td className="opacity-50">Orientation:</td><td>{item.orientation}</td></tr>}
                                {item.closure && <tr><td className="opacity-50">Closure:</td><td>
                                {Array.isArray(item.closure) ? (
                                    item.closure.map((item, index) => {
                                      return (
                                        <>{item}{` `}</>
                                      )
                                    })
                                  ) : (
                                    <>{item.closure}</>
                                  )}      
                                  </td></tr>}
                                {item.material && <tr><td className="opacity-50">Material:</td><td>{item.material}{item.specialtySpec && ` - ${item.specialtySpec}`}</td></tr>}
                                {item.laminate && item.laminate !== 'None' &&  <tr><td className="opacity-50">Laminate:</td><td>{item.laminate}</td></tr>}
                                {item.printing && <tr><td className="opacity-50">Printing:</td><td>{item.printing}</td></tr>}
                                {item.colour && <tr><td className="opacity-50">Colour:</td><td>{item.colour}</td></tr>}
                                <tr><td className="opacity-50">Additions:</td><td>{item.emboss && item.emboss}{item.emboss && item.foil && ' | '}{item.foil && item.foil}</td></tr>
                                {item.quantity && <tr><td className="opacity-50">Quantity:</td><td>{item.quantity}{(item.quantity === 'Custom' && item.quantityCustom) && ` - ${item.quantityCustom}`}</td></tr>}
                                {item.dieline && <tr><td className="opacity-50">Dieline:</td><td>{item.dieline}</td></tr>}
                                {item.notes && <tr><td className="opacity-50">Notes:</td><td>{item.notes}</td></tr>}
                              </table>
                          </div>                          
                        </li>
                    )
                })
            ) : (
                <li className="flex flex-col items-start pt-4 text-white border-t border-white/50">
                  <p>Your cart is empty.</p>
                  <Link className="mt-12 lg:mt-20 btn-rnd-white" to={`/packaging`}>Continue browsing</Link>
                </li>
            )}
            </ul>                

      </section>
    </Layout>
  );
};

export default Checkout;
